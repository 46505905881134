import React from 'react';

const ExamplePage = () => (
  <div>
    <h1>Contact Page</h1>
    <p>This is my site. Take a look around!</p>
  </div>
);

export default ExamplePage;
