import React from 'react'
import SocialMedia from './SocialMedia'

const Footer = () => (
    <footer className="footer py-4">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-lg-4 text-lg-left">Copyright ©
                    dumalang.dev 2023
                </div>
                <div className="col-lg-4 my-3 my-lg-0">
                    <SocialMedia />
                </div>
                {/*<div className="col-lg-4 text-lg-right">*/}
                {/*    <a className="mr-3" href="#!">Privacy Policy</a>*/}
                {/*    <a href="#!">Terms of Use</a>*/}
                {/*</div>*/}
            </div>
        </div>
    </footer>
)

export default Footer
