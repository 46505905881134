import { library } from '@fortawesome/fontawesome-svg-core'

import {
    faGithub,
    faFacebookF,
    faTwitter,
    faLinkedinIn,
    faInstagram,
} from '@fortawesome/free-brands-svg-icons'

library.add(
    faGithub,
    faFacebookF,
    faTwitter,
    faLinkedinIn,
    faInstagram
)