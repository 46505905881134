import React from 'react'

import Header from './Header'
import Profile from './Profile'
import Skills from './Skills'
import Experiences from './Experiences'
import Frameworks from './Frameworks'
import Footer from './Footer'
import Playground from './Playground'
import Contact from './Contact'
import MainNav from "../../Nav/MainNav";

const HomePage = () => (
    <div>
        <MainNav/>
        <Header/>
        <Profile/>
        {/*<Skills/>*/}
        {/*<Frameworks/>*/}
        <Experiences/>
        {/*<Playground/>*/}
        <Contact/>
        <Footer/>
    </div>
)

export default HomePage
