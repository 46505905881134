import React, { Component } from 'react'
import $ from 'jquery'
import Typing from '../Pages/HomePage/Typing'
import { Link } from 'react-scroll'
import { Navbar, Nav } from 'react-bootstrap'

type Data = {
    mainNavClass: ''
}

class MainNav extends Component<{}, Data> {

    componentDidMount () {

        // Collapse Navbar
        var navbarCollapse = function () {
            let $x = $('#mainNav').offset()

            // @ts-ignore
            if ($x.top > 100) {
                $('#mainNav').addClass('navbar-shrink')
                $('#mainNav').removeClass('bg-transparent')
            } else {
                $('#mainNav').removeClass('navbar-shrink')
                $('#mainNav').addClass('bg-transparent')
            }
        }

        $(window).scroll(navbarCollapse)
    }

    render () {

        const menu = [
            {
                'link': 'profile',
                'title': 'Profile',
            }, {
                'link': 'experiences',
                'title': 'Experiences',
            }, {
                'link': 'contact',
                'title': 'Contact',
            },
        ]

        // const brandStyle = css({ 'fontFamily': 'monospace' })

        return <Navbar id="mainNav"
                       fixed="top"
                       collapseOnSelect
                       expand="lg"
                       className="bg-transparent">
            <div className="container">
                <Link
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
                    className="navbar-brand js-scroll-trigger"
                    to="header"
                >
                    <Typing title={'Jimmy Dumalang'} blinking={true}/>
                </Link>
                <Navbar.Toggle aria-controls="navbarResponsive"
                               className="navbar-toggler navbar-toggler-right"/>
                <Navbar.Collapse className={'collapse navbar-collapse'}
                                 id="navbarResponsive">
                    <Nav className="navbar-nav text-uppercase ml-auto">
                        {
                            menu.map((link, key) => {
                                return <li className="nav-item" key={key}>
                                    <Link
                                        activeClass="active"
                                        spy={true}
                                        smooth={true}
                                        offset={-70}
                                        duration={500}
                                        className="nav-link js-scroll-trigger"
                                        to={link.link}
                                    >
                                        {link.title}
                                    </Link>
                                </li>
                            })
                        }
                        {/*<li className="nav-item" key="9999">*/}
                        {/*<Link*/}
                        {/*    className="nav-link js-scroll-trigger"*/}
                        {/*    to={"contact"}*/}
                        {/*>*/}
                        {/*    Contact*/}
                        {/*</Link>*/}
                        {/*</li>*/}
                    </Nav>
                </Navbar.Collapse>
            </div>
        </Navbar>

    }

}

export default MainNav
