import React from 'react'
import Typing from './Typing'
import { Link, animateScroll as scroll } from 'react-scroll'

const Header = () => (
    <header className="masthead" id={"header"}>
        <div className="container">
            <div className="masthead-subheading">Hi!</div>
            <div className="masthead-heading text-uppercase"><Typing
                title={'I\'m Jimmy'}/></div>
            <Link
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                className="btn btn-primary btn-xl text-uppercase js-scroll-trigger"
                to="profile"
            >
                Know Me More
            </Link>
        </div>
    </header>
)

export default Header
