import React from 'react';

const ChordAppSongs = () => (
  <div>
    <h1>Chord App Songs</h1>
    <p>This is my site. Take a look around!</p>
  </div>
);

export default ChordAppSongs;
