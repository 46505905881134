import React from 'react';

const NoteFoundPage = () => (
  <div>
    <h1>404</h1>
    <p>This is my site. Take a look around!</p>
  </div>
);

export default NoteFoundPage;
