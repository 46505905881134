import React from 'react'
import {
    FontAwesomeIcon,
} from '@fortawesome/react-fontawesome'

const SocialMedia = () => {
    const socialMedias = [
        {
            'link': 'https://www.linkedin.com/in/jimmy-richard-timotius-dumalang-82100a25/',
            'fa': <FontAwesomeIcon icon={
                ['fab', 'linkedin-in']
            }/>,
        }, {
            'link': 'https://web.facebook.com/jdumalang?_rdc=1&_rdr',
            'fa': <FontAwesomeIcon icon={
                ['fab', 'facebook-f']
            }/>,
        }, {
            'link': 'https://twitter.com/jimmydumalang',
            'fa': <FontAwesomeIcon icon={
                ['fab', 'twitter']
            }/>,
        }, {
            'link': 'https://instagram.com/jimmydumalang',
            'fa': <FontAwesomeIcon icon={
                ['fab', 'instagram']
            }/>,
        }, {
            'link': 'https://github.com/dumalang',
            'fa': <FontAwesomeIcon icon={
                ['fab', 'github']
            }/>,
        },
    ]
    return <>
        {
            socialMedias.map((socialMedia, key) => {
                // @ts-ignore
                return <a className="btn btn-dark btn-social mx-2"
                          target="_blank"
                          href={socialMedia.link} key={key}>
                    {socialMedia.fa}
                </a>
            })
        }
    </>
}

export default SocialMedia