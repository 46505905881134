import React from 'react'
import Typing from './Typing'

const Experiences = () => {

    const experiences = [
        {
            'period': '2021-Now',
            'company': 'Adira Finance',
            'position': 'Head of Digital Engineering',
            // 'image': 'assets/img/experiences/logo-adira.jpg',
            'desc': '',
        },
        {
            'period': '2017-2021',
            'company': 'Pawoon POS',
            'position': 'Tech Lead',
            // 'image': 'assets/img/experiences/logo-pawoon.jpg',
            'desc': '',
        }, {
            'period': '2012-2017',
            'company': 'Upajiwa Business Solution',
            'position': 'Software Engineer',
            // 'image': 'assets/img/experiences/logo-ubs.jpg',
            'desc': '',
        },
    ]

    return <section className="page-section" id="experiences">
        <div className="container">
            <div className="text-center">
                <h2 className="section-heading text-uppercase"><Typing
                    title={'Experiences'}/></h2>
                <h3 className="section-subheading text-muted">My
                    professional career journey</h3>
            </div>
            <ul className="timeline">
                {
                    experiences.map((experience, key) => {
                        let liClass = key % 2 ? 'timeline-inverted' : 'timeline'
                        return <li key={key} className={liClass}>
                            {/*<div className="timeline-image"><img*/}
                            {/*    className="rounded-circle img-fluid"*/}
                            {/*    src={experience.image}*/}
                            {/*    alt=""/></div>*/}
                            <div className="timeline-panel">
                                <div className="timeline-heading">
                                    <h4>{experience.period}</h4>
                                    <h4 className="subheading">{experience.company}</h4>
                                    <h4 className="subheading">{experience.position}</h4>
                                </div>
                                <div className="timeline-body"><p
                                    className="text-muted">{experience.desc}</p>
                                </div>
                            </div>
                        </li>
                    })
                }
            </ul>
        </div>
    </section>
}

export default Experiences
