import {useEffect, useState} from 'react';

type TypingProps = {
    title: string,
    blinking?: boolean,
    typingDelay?: number
}

export const Typing = (typing: TypingProps) => {

    const [text, setText] = useState(" ");
    const [showCursor, setShowCursor] = useState(true);
    const typingDelay = typing.typingDelay || 500; // default delay of 50ms
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        if (typing.blinking) {
            const intervalCursor = setInterval(() => {
                setShowCursor(prevState => !prevState);
            }, 500);

            return () => {
                clearInterval(intervalCursor);
            };
        } else {
            setShowCursor(false)
        }
    }, []);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (currentIndex < text.length) {
                setText((prevText) => prevText + typing.title.charAt(currentIndex));
                setCurrentIndex((prevIndex) => prevIndex + 1);
            }
        }, typingDelay);

        return () => clearTimeout(timer);
    }, [currentIndex, text, typingDelay]);

    return (
        <div>
      {text}{showCursor ? <span>_</span> : <span> </span>}
    </div>
    );
}

export default Typing
