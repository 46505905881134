import React from 'react';

const ChordAppArtist = () => (
  <div>
    <h1>Chord App Artist</h1>
    <p>This is my site. Take a look around!</p>
  </div>
);

export default ChordAppArtist;
