import React, { Component } from 'react'
import SocialMedia from './SocialMedia'
import Typing from './Typing'
import { Link } from 'react-router-dom'

type ProfileState = {
    workingYears: string,
}

export class Profile extends Component<{}, ProfileState> {

    constructor (props: {}) {
        super(props)

        const startYear = 2012
        var d = new Date()
        var thisYear = d.getFullYear()

        this.state = {
            workingYears: (thisYear - startYear).toString(),
        }
    }

    render () {
        return <section className="page-section bg-light" id="profile">
            <div className="container">
                <div className="text-center">
                    <h2 className="section-heading text-uppercase">
                        <Typing title={'My Profile'}/>
                    </h2>
                    <h3 className="section-subheading text-muted"></h3>
                </div>
                <div className="row">
                    <div className="offset-lg-4 col-lg-4 offset-lg-4">
                        <div className="team-member">
                            <img className="mx-auto rounded-circle"
                                 src="assets/img/team/2.jpeg" alt=""/>
                            <h4>Jimmy Dumalang</h4>
                            <p className="text-muted">Software Engineer</p>
                            <SocialMedia/>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-8 mx-auto text-center">
                        <p className="large text-muted">
                        </p>
                    </div>
                </div>
            </div>
        </section>
    }

}

export default Profile
