import React from 'react';

const ChordAppIndex = () => (
  <div>
    <h1>Chord App</h1>
    <p>This is my site. Take a look around!</p>
  </div>
);

export default ChordAppIndex;
