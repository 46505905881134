import React, { Component, FormEvent } from 'react'
import Swal from 'sweetalert2'
import axios from 'axios'

const axiosClient = axios.create({
    validateStatus: () => true,
})

type ContactState = {
    name: string,
    email: string,
    phone: string,
    message: string
}

export class Contact extends Component<{}, ContactState> {

    constructor (props: {}) {
        super(props)
        this.state = {
            name: '',
            email: '',
            phone: '',
            message: '',
        }

        this.handleChangeName = this.handleChangeName.bind(this)
        this.handleChangeEmail = this.handleChangeEmail.bind(this)
        this.handleChangePhone = this.handleChangePhone.bind(this)
        this.handleChangeMessage = this.handleChangeMessage.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    handleChangeName (event: React.FormEvent<HTMLInputElement>) {
        this.setState({ name: event.currentTarget.value })
    }

    handleChangeEmail (event: React.FormEvent<HTMLInputElement>) {
        this.setState({ email: event.currentTarget.value })
    }

    handleChangePhone (event: React.FormEvent<HTMLInputElement>) {
        this.setState({ phone: event.currentTarget.value })
    }

    handleChangeMessage (event: React.FormEvent<HTMLTextAreaElement>) {
        this.setState({ message: event.currentTarget.value })
    }

    async handleSubmit (event: FormEvent) {
        event.preventDefault()
        try {
            const response = await this.postMessage()
            console.log("response", response)
            switch (response.status) {
                case 201:
                    Swal.fire('Thank You',
                        'I\'ll contact you through email or WhatsApp, soon!',
                        'success')
                    this.setState({
                        name: "",
                        email: "",
                        phone: "",
                        message: "",
                    })
                    break
                case 422:
                    let listError: string[] = []
                    for (let i in response.data.errors) {
                        listError.push(
                            `<div>${response.data.errors[i].path}: ${response.data.errors[i].msg}</div>`
                        )
                    }
                    let errorMessage = '<div>' + listError.join('') + '</div>'
                    Swal.fire('Invalid Input',
                        errorMessage, 'error')
                    break
                default:
                    Swal.fire('Invalid Input',
                        response.data.message, 'error')
                    break
            }
        } catch (e: any) {
            Swal.fire('Network error',
                e.message, 'error')
            console.log("e.message", e.message)
        }
    }

    postMessage () {
        return axiosClient.post('https://api.dumalang.dev/api/messages', {
            name: this.state.name,
            email: this.state.email,
            phone: this.state.phone,
            message: this.state.message,
        }, {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
            }
        })
    }

    render () {
        return <section className="page-section" id="contact">
            <div className="container">
                <div className="text-center">
                    <h2 className="section-heading text-uppercase">Contact
                        Me</h2>
                    <h3 className="section-subheading text-muted"></h3>
                    <div className={'additional-margin-top'}></div>
                </div>
                <form id="contactForm" name="sentMessage"
                      noValidate={true}
                      onSubmit={this.handleSubmit}>
                    <div className="row align-items-stretch mb-5">
                        <div className="col-md-6">
                            <div className="form-group">
                                <input className="form-control" id="name"
                                       value={this.state.name}
                                       onChange={this.handleChangeName}
                                       type="text" placeholder="Your Name *"
                                       data-validation-required-message="Please enter your name."/>
                                <p className="help-block text-danger"></p>
                            </div>
                            <div className="form-group">
                                <input className="form-control" id="email"
                                       value={this.state.email}
                                       onChange={this.handleChangeEmail}
                                       type="email" placeholder="Your Email *"
                                       data-validation-required-message="Please enter your email address."/>
                                <p className="help-block text-danger"></p>
                            </div>
                            <div className="form-group mb-md-0">
                                <input className="form-control" id="phone"
                                       value={this.state.phone}
                                       onChange={this.handleChangePhone}
                                       type="tel" placeholder="Your Phone *"
                                       data-validation-required-message="Please enter your phone number."/>
                                <p className="help-block text-danger"></p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div
                                className="form-group form-group-textarea mb-md-0">
                                <textarea className="form-control" id="message"
                                          value={this.state.message}
                                          onChange={this.handleChangeMessage}
                                          placeholder="Your Message *"
                                          data-validation-required-message="Please enter a message."></textarea>
                                <p className="help-block text-danger"></p>
                            </div>
                        </div>
                    </div>
                    <div className="text-center">
                        <div id="success"></div>
                        <div className={'additional-margin-top'}></div>
                        <button
                            className="btn btn-primary btn-xl text-uppercase"
                            id="sendMessageButton" type="submit"
                        >Send Message
                        </button>
                    </div>
                </form>
            </div>
        </section>
    }
}

export default Contact